<template>
  <div
    class="flex flex-wrap items-center min-h-10 w-full border rounded p-1 text-sm text-headline leading-7"
  >
    <lf-badge
      v-for="(email, index) in validEmails"
      :key="email"
      removable
      @click:remove="removeEmail(index)"
    >
      {{ email }}
    </lf-badge>
    <input
      type="text"
      class="flex-grow min-w-[50%] whitespace-pre focus:outline-none focus:border-transparent border-0 focus:border-0 px-2 focus:ring-0"
      :class="{ 'border border-disabled rounded': !!validEmails.length }"
      data-cy="email-input-multiple"
      v-model="inputValue"
      @input="handleContentChange"
      @blur="handleContentChange"
    />
  </div>
</template>

<script setup lang="ts">
import { useNotification } from "@/hooks/notifications";
import uniq from "lodash/uniq";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const props = defineProps<{
  value: string | null;
  emails: string[];
}>();

const emit = defineEmits<{
  change: [{ validEmails: string[]; input: string }];
}>();

const { showMessage } = useNotification();
const { t } = useI18n();

const validEmails = ref<string[]>(props.emails || []);
const inputValue = ref<string>(props.value || "");
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const handleContentChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const input = target.value.trim();
  const shouldCheckForEmails = input.endsWith(",") || event.type === "blur";
  if (!shouldCheckForEmails) {
    return;
  }
  const emails = input.split(",").map((email) => email.trim());

  const addedEmails = [
    ...validEmails.value,
    ...emails.filter((email) => email.match(emailRegex))
  ];

  if (new Set(addedEmails).size !== addedEmails.length) {
    showMessage(t("EMAIL_INTEGRATION.UNIQUE_EMAIL_WARNING"), "warning");
  }

  validEmails.value = uniq(addedEmails);
  const invalidEmails = emails
    .filter((email) => !email.match(emailRegex))
    .join(", ");
  inputValue.value = invalidEmails;
  emit("change", { validEmails: validEmails.value, input: invalidEmails });
};

const removeEmail = (index: number) => {
  validEmails.value.splice(index, 1);
  emit("change", { validEmails: validEmails.value, input: inputValue.value });
};
</script>
